import React, { useState } from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const NAVBAR = graphql`
  query Navbar {
    markdownRemark(frontmatter: { dataFor: { eq: "footer" } }) {
      frontmatter {
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const Navbar = () => {
  const data = useStaticQuery(NAVBAR);

  const [active, setActive] = useState(false);

  const toggleHamburger = () => {
    setActive(!active);
  };
  return (
    <nav
      className="navbar is-transparent fixed-top"
      role="navigation"
      aria-label="main-navigation"
      style={{ paddingTop: 7, paddingBottom: 7 }}
    >
      <div className="container">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item" title="Logo">
            {data.markdownRemark.frontmatter.image ? (
              <PreviewCompatibleImage
                imageInfo={{
                  image: data.markdownRemark.frontmatter.image,
                  alt: `Logo for navbar`,
                  style: { width: '60px', objectFit: 'none', maxHeight: '25px' }
                }}
              />
            ) : // <img src={data.markdownRemark.frontmatter.image.childImageSharp.fluid.src}></img>
            null}{' '}
          </Link>
          <div
            className={`navbar-burger burger ${active ? 'is-active' : ''}`}
            data-target="navMenu"
            onClick={() => toggleHamburger()}
          >
            <span />
            <span />
            <span />
          </div>
        </div>
        <div id="navMenu" className={`navbar-menu ${active ? 'is-active' : ''}`}>
          <div className="navbar-start has-text-centered">
            <Link className="navbar-item" to="/">
              Home
            </Link>
            <Link className="navbar-item" to="/about">
              About
            </Link>
            <Link className="navbar-item" to="/products">
              Products
            </Link>
            <Link className="navbar-item" to="/machines">
              Machines
            </Link>
            <Link className="navbar-item" to="/contact">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
