import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PreviewCompatibleImage from './PreviewCompatibleImage';

const FOOTER = graphql`
  query Footer {
    markdownRemark(frontmatter: { dataFor: { eq: "footer" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;

const Footer = () => {
  const data = useStaticQuery(FOOTER);

  return (
    <footer className="footer" style={{ marginTop: 50 }}>
      <div className="content has-text-centered center-image">
        {data.markdownRemark.frontmatter.image ? (
          <PreviewCompatibleImage
            imageInfo={{
              image: data.markdownRemark.frontmatter.image,
              alt: `Logo for footer`,
              style: { maxHeight: '100px', maxWidth: '100px', margin: '0 auto' }
            }}
          />
        ) : null}
      </div>

      <div className="content has-text-centered">
        <p>{data.markdownRemark.frontmatter.title}</p>
      </div>
    </footer>
  );
};

export default Footer;
